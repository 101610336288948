* + .container { // the icon has a sibling to the left (give the icon a margin for separation)
  margin-left: 0.4em;
}

.container {
  display: inline-block;

  &.rotate90 {
    transform: rotate(90deg);
  }

  &.rotate180 {
    transform: rotate(180deg);
  }

  &.rotate270 {
    transform: rotate(270deg);
  }

  + * { // the icon has a sibling to the right (give the sibling a margin for separation)
    margin-left: 0.4em;
  }
}
