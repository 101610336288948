@import '~styles/styleTools';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-size: 14px;

  .content {
    max-width: 475px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      color: #fff;
      font-size: 50px;
      margin-bottom: 40px;
      min-height: 50px;
    }

    .formContainer {
      width: 470px;
      min-height: 604px;
      border-radius: 4px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;

      .header {
        margin-top: 24px;
        font-size: 24px;
        font-weight: $font-weight-light;
        max-width: 400px;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-bottom: 27px;

        .formGroup:first-of-type {
          margin-top: 32px;
        }

        .formGroup:last-of-type {
          margin-top: 32px;
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          height: 86px;
        }


        .label {
          font-size: 16px;
          margin: 0 0 5px 0;
          font-weight: $font-weight-light
        }

        .input {
          width: 310px;
          height: 30px;
          margin-bottom: 5px;
          border-radius: 4px;
          border: solid 1px #ccc;
          background-color: #ffffff;
          padding-left: 8px;
        }

        .error {
          font-size: 10px;
          color: #b31500;
          font-style: italic;
        }

        .submitButton {
          width: 166px;
          height: 40px;
          margin: 27px auto 0;
        }

        .accept {
          margin-top: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 310px;

          span {
            margin-left: 8px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
