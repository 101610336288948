/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
$local-color-grey-light: #d8d8d8;


/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
$color-background: mix($ls-color-black, $ls-color-white, 5%); // rgba($ls-color-black, 0.05);
$color-borders-and-dividers: $local-color-grey-light;

$color-content-background: $ls-color-white;
$color-content-text: $ls-color-graphite;

$color-error-text: $ls-color-red;

// header: the very top of the page
$color-header-background: $ls-color-purple-dark;
$color-header-text: $ls-color-white;

// heading: the top of the content area
$color-heading-background: $ls-color-white;
$color-heading-text: $ls-color-graphite;

$color-nav-background: $ls-color-graphite;
$color-nav-text: $ls-color-white;
$color-nav-link-background: transparent;
$color-nav-link-background-active: rgba($ls-color-black, 0.2);
$color-nav-link-background-hover: rgba($ls-color-black, 0.2);
$color-nav-link-text: rgba($ls-color-white, 0.6);
$color-nav-link-text-active: $ls-color-white;
$color-nav-link-text-hover: rgba($ls-color-white, 0.6);



/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
$base-font-size: 12px;
$font-family: ls-text-font, sans-serif;
$font-size-h1: 30px; // 2.5rem
$font-size-h2: 28px; // 2.333333333rem
$font-size-h3: 24px; // 2rem
$font-size-h4: 20px; // 1.666666667rem
$font-size-h5: 18px; // 1.5rem
$font-size-h6: 16px; // 1.333333333rem

$font-size-xxl: 17px; // 1.416666667rem
$font-size-xl: 16px; // 1.333333333rem
$font-size-lg: 14px; // 1.166666667rem
$font-size-mlg: 13px; // 1.083333333rem
$font-size-md: 12px; // 1rem
$font-size-sm: 11px; // 0.916666667rem
$font-size-xs: 10px; // 0.833333333rem
$font-size-xxs: 9px;
