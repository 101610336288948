.container {
  width: 310px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #ffffff;
  padding-left: 8px;
  position: relative;
  .dropdownInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 25px;
    padding: 5px 0;
    .arrowDown {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
      margin: 5px 10px 0 0;
    }
  }
  .dropdown {
    position: absolute;
    top: 25px;
    left: 0;
    background: #fff;
    z-index: 10;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 10px 0px black;
    width: 100%;
    margin: 0;
    padding: 0;
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    ul li {
      list-style-type: none;
      border-bottom: 1px solid #ddd;
      width: 100%;
      color: #4b4f54;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
    }
    ul li:last-of-type {
      border-bottom: 0;
    }
    ul li:hover {
      background-color: #ddd;
    }
  }
}