@import 'styleTools'; // your locally bundled tools (functions, constants, mixins, etc.) also usable in name-spaced modules
@import '~@lifesize/ux-assets/styles/styleFonts';
@import '~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/style.scss';
@import '~@lifesize/ux-assets/styles/styleCore';
/*
 * Common, global styles specific to this project
 *
 * these styles have been separated from the localized styles in webpack.config.js because using a :global {} wrapper
 * causes some issues with source urls (...src: url()) within :global {}. This approach seems a bit cleaner
 */

html {
  font-size: $base-font-size; // html is the root element (for rem)
}

body {
  font-family: $font-family !important;  // override Semantic UI
  background-color: $ls-color-purple;
  overflow: auto !important;

  button,
  input,
  select,
  textarea {
    font-family: $font-family !important;  // override Semantic UI
    font-size: $font-size-lg !important; // override Semantic UI
  }

  input,
  select,
  textarea {
    font-weight: $font-weight-light;
  }

  input::-ms-clear { // TODO: move to ux-assets
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: $font-weight-light;
  }
  h1 { font-size: $font-size-h1; }
  h2 { font-size: $font-size-h2; }
  h3 { font-size: $font-size-h3; }
  h4 { font-size: $font-size-h4; }
  h5 { font-size: $font-size-h5; }
  h6 { font-size: $font-size-h6; }
}