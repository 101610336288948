@charset "UTF-8";
/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
/*
 * Lifesize Font Faces
 * The font-family name 'ls-text-font' is used in each of the text font declarations. This is done so that we can simply
 * define a css font-family as 'ls-text-font', then depending on the other styles defined (bold, italic, etc), css will
 * then automatically select the correct font face.
 * Note: The font-family is already defined in styleCore.scss and should not need to be specified anywhere else.
 */
@font-face {
  font-family: 'ls-text-font';
  font-style: normal;
  font-weight: normal;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-Reg.woff") format("woff"); }

@font-face {
  font-family: 'ls-text-font';
  font-style: normal;
  font-weight: bold;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-Bol.woff") format("woff"); }

@font-face {
  font-family: 'ls-text-font';
  font-style: italic;
  font-weight: bold;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-BolIta.woff") format("woff"); }

@font-face {
  font-family: 'ls-text-font';
  font-weight: 200;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-Lig.woff") format("woff"); }

@font-face {
  font-family: 'ls-text-font';
  font-weight: 500;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-Med.woff") format("woff"); }

@font-face {
  font-family: 'ls-text-font';
  font-style: italic;
  font-weight: normal;
  src: url("~@lifesize/ux-assets/fonts/webclient/NimbusSanL-RegIta.woff") format("woff"); }

/*
 * The style-linked fonts will resolve as follows...
 *
 * ------------------------------------------------------------------------------------------
 * style\weight |   light (200)    |     'normal'     |   medium (500)   |      'bold'      |
 * -------------|------------------|------------------|------------------|------------------|
 *    normal    | NimbusSanL-Lig   | NimbusSanL-Reg   | NimbusSanL-Med   | NimbusSanL-Bol   |
 * -------------|------------------|------------------|------------------|------------------|
 *    italic    | NimbusSanL-Lig   | NimbusSanL-RegIta| NimbusSanL-Med   | NimbusSanL-BolIta|
 *              | * italic applied |                  | * italic applied |                  |
 *              |   by browser     |                  |   by browser     |                  |
 * ------------------------------------------------------------------------------------------
 *
 * tip: when specifying font-weight, use the constants defined in _styleConstants.scss
 * note: the true rendered font family is visible in the computed styles at the very bottom in a section labeled
 *       "Rendered Fonts" (in this case, "NimbusSanL", however the font file referenced above will be used)
 */
@font-face {
  font-family: "ls-desktop-and-web-iconography";
  src: url("~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/fonts/ls-desktop-and-web-iconography.eot?vxi58s");
  src: url("~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/fonts/ls-desktop-and-web-iconography.eot?vxi58s#iefix") format("embedded-opentype"), url("~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/fonts/ls-desktop-and-web-iconography.ttf?vxi58s") format("truetype"), url("~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/fonts/ls-desktop-and-web-iconography.woff?vxi58s") format("woff"), url("~@lifesize/ux-assets/fonts/webclient/ls-desktop-and-web-iconography/fonts/ls-desktop-and-web-iconography.svg?vxi58s#ls-desktop-and-web-iconography") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ls-desktop-and-web-iconography" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-ellipses:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-x-thick:before {
  content: ""; }

.icon-trash:before {
  content: ""; }

.icon-time:before {
  content: ""; }

.icon-bt-1:before {
  content: ""; }

.icon-bt-2:before {
  content: ""; }

.icon-bt-3:before {
  content: ""; }

.icon-arrow:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-add:before {
  content: ""; }

.icon-audio-call:before {
  content: ""; }

.icon-audio-call-pstn:before {
  content: ""; }

.icon-avatar:before {
  content: ""; }

.icon-back:before {
  content: ""; }

.icon-call-incoming:before {
  content: ""; }

.icon-call-missed:before {
  content: ""; }

.icon-call-outgoing:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-camera-down:before {
  content: ""; }

.icon-camera-left:before {
  content: ""; }

.icon-camera-right:before {
  content: ""; }

.icon-camera-up:before {
  content: ""; }

.icon-cancel:before {
  content: ""; }

.icon-chat:before {
  content: ""; }

.icon-chat-outline:before {
  content: ""; }

.icon-chat-send-message:before {
  content: ""; }

.icon-check-circle-checked:before {
  content: ""; }

.icon-check-circle-unchecked:before {
  content: ""; }

.icon-checkmark:before {
  content: ""; }

.icon-compose-2:before {
  content: ""; }

.icon-contact-profile:before {
  content: ""; }

.icon-contact-profile-outline:before {
  content: ""; }

.icon-directory:before {
  content: ""; }

.icon-directory-outline:before {
  content: ""; }

.icon-end-call:before {
  content: ""; }

.icon-favorite:before {
  content: ""; }

.icon-favorite-outline:before {
  content: ""; }

.icon-full-screen-enter:before {
  content: ""; }

.icon-full-screen-exit:before {
  content: ""; }

.icon-home:before {
  content: ""; }

.icon-home-outline:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-leave-call:before {
  content: ""; }

.icon-manual-dial:before {
  content: ""; }

.icon-manual-dial-hash:before {
  content: ""; }

.icon-meeting:before {
  content: ""; }

.icon-mic:before {
  content: ""; }

.icon-mic-off:before {
  content: ""; }

.icon-recents:before {
  content: ""; }

.icon-record:before {
  content: ""; }

.icon-record-stop:before {
  content: ""; }

.icon-room-system:before {
  content: ""; }

.icon-schedule:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-settings:before {
  content: ""; }

.icon-share-screen:before {
  content: ""; }

.icon-video:before {
  content: ""; }

.icon-video-off:before {
  content: ""; }

.icon-zoom-in:before {
  content: ""; }

.icon-zoom-out:before {
  content: ""; }

.icon-kebab:before {
  content: ""; }

.icon-Email:before {
  content: ""; }

.icon-clipboard:before {
  content: ""; }

.icon-down:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-exclamation:before {
  content: ""; }

.icon-check-off:before {
  content: ""; }

.icon-check-on:before {
  content: ""; }

.icon-incall-add:before {
  content: ""; }

.icon-walkme:before {
  content: ""; }

.icon-turtle:before {
  content: ""; }

.icon-compAudio:before {
  content: ""; }

.icon-help-outline:before {
  content: ""; }

.icon-return:before {
  content: ""; }

.icon-loader:before {
  content: ""; }

.icon-ic-alert:before {
  content: ""; }

.icon-buddy:before {
  content: ""; }

.icon-copy:before {
  content: ""; }

.icon-copy-outline:before {
  content: ""; }

.icon-dnd:before {
  content: ""; }

.icon-export:before {
  content: ""; }

.icon-features:before {
  content: ""; }

.icon-other:before {
  content: ""; }

.icon-otm:before {
  content: ""; }

.icon-phoneHD:before {
  content: ""; }

.icon-quick:before {
  content: ""; }

.icon-stream:before {
  content: ""; }

.icon-volume-more:before {
  content: ""; }

.icon-ic-display:before {
  content: ""; }

.icon-ic-network:before {
  content: ""; }

.icon-file-manager:before {
  content: ""; }

.icon-turtle-hover:before {
  content: ""; }

.icon-turtle-normal:before {
  content: ""; }

.icon-popout:before {
  content: ""; }

.icon-clock-o:before {
  content: ""; }

.icon-share-alt:before {
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-dnd-alt:before {
  content: ""; }

.icon-start:before {
  content: ""; }

.icon-camera-icon:before {
  content: ""; }

.icon-directory-alt:before {
  content: ""; }

.icon-favorites:before {
  content: ""; }

.icon-manual-dial-keypad:before {
  content: ""; }

.icon-meetings:before {
  content: ""; }

.icon-recent-calls:before {
  content: ""; }

.icon-calendar:before {
  content: ""; }

.icon-calendar-alt:before {
  content: ""; }

.icon-search-alt:before {
  content: ""; }

.icon-remove:before {
  content: ""; }

.icon-edit2:before {
  content: ""; }

.icon-present:before {
  content: ""; }

.icon-buddy-noTM:before {
  content: ""; }

.icon-lsLogo:before {
  content: ""; }

.icon-upload:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-list-ul:before {
  content: ""; }

.icon-volume:before {
  content: ""; }

.icon-health:before {
  content: ""; }

.icon-sleep:before {
  content: ""; }

.icon-myinfo:before {
  content: ""; }

.icon-results:before {
  content: ""; }

.icon-data:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-questions:before {
  content: ""; }

.icon-th:before {
  content: ""; }

.icon-bullhorn:before {
  content: ""; }

.icon-bell-o:before {
  content: ""; }

.icon-thumbs-up:before {
  content: ""; }

.icon-view:before {
  content: ""; }

/*
 * Shared common, global styles
 */
html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  box-sizing: border-box;
  font-weight: normal;
  height: 100%;
  line-height: 1.2;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%; }
  body > div {
    height: 100%; }
  body *:focus {
    outline: none; }
  body a {
    color: #00afaa;
    text-decoration: none;
    background-color: transparent; }
    body a:hover {
      color: #00afaa;
      text-decoration: none;
      cursor: pointer; }
  body b, body strong {
    font-weight: bold; }
  body blockquote {
    margin: 0 0 1rem; }
  body figure {
    margin: 0 0 1rem; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    margin-top: 0;
    margin-bottom: 1rem; }
  body hr {
    border: none;
    height: 1px;
    margin: 0;
    padding: 0; }
  body img {
    border-style: none;
    max-width: 100%; }
  body input,
  body button,
  body select,
  body optgroup,
  body textarea {
    margin: 0; }
  body button {
    overflow: visible; }
  body label {
    display: inline-block; }
  body dl, body ol, body p, body ul {
    margin-top: 0;
    margin-bottom: 1rem; }
  body ol ol, body ol ul, body ul ol, body ul ul {
    margin-bottom: 0; }
  body pre,
  body code,
  body kbd,
  body samp {
    font-family: monospace;
    font-size: 1em; }
  body pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }
  body small {
    font-size: 80%; }
  body sub,
  body sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  body sub {
    bottom: -.25em; }
  body sup {
    top: -.5em; }
  body video {
    margin-top: 0; }

/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

* {
  min-height: 0;
  min-width: 0; }

dialog, main {
  display: block; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  caption-side: bottom; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*
 * Common, global styles specific to this project
 *
 * these styles have been separated from the localized styles in webpack.config.js because using a :global {} wrapper
 * causes some issues with source urls (...src: url()) within :global {}. This approach seems a bit cleaner
 */
html {
  font-size: 12px; }

body {
  font-family: ls-text-font, sans-serif !important;
  background-color: #4b45da;
  overflow: auto !important; }
  body button,
  body input,
  body select,
  body textarea {
    font-family: ls-text-font, sans-serif !important;
    font-size: 14px !important; }
  body input,
  body select,
  body textarea {
    font-weight: 200; }
  body input::-ms-clear {
    display: none; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    font-weight: 200; }
  body h1 {
    font-size: 30px; }
  body h2 {
    font-size: 28px; }
  body h3 {
    font-size: 24px; }
  body h4 {
    font-size: 20px; }
  body h5 {
    font-size: 18px; }
  body h6 {
    font-size: 16px; }
